import Image from "next/image";
import Link from "next/link";

const SingleCoin = (props) => {
  const data = Array.isArray(props.data) ? props.data : [];
  return (
    <>
     
      <div className="flex flex-col gap-4">
        <h2 className="mb-6 text-center font-display text-3xl font-medium text-jacarta-700 dark:text-white">
          Product dan Retail
        </h2>
        {data
          .filter((item) => item.kategori === "product dan retail")
          .map((item) => (
            <Link
              href={item.link}
              className="flex rounded-2.5xl border border-jacarta-100 bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent dark:bg-jacarta-700"
              key={item.id}
            >
              <figure className="mr-4 shrink-0">
                <Image
                  src={item.img}
                  alt="coin icon"
                  width={100}
                  height={100}
                  className="h-100 w-100 rounded-2lg"
                  loading="lazy"
                />
              </figure>
              <div>
                <span className="block font-display font-semibold text-jacarta-700 dark:text-white">
                  {item.name}
                </span>

                <span className="text-sm dark:text-jacarta-300">
                  <span className="text-green">{item.deskripsi}</span>
                </span>
                <span className="py-4">
                  <br />
                  <Image
                    width={40}
                    height={40}
                    src={item.authorImage}
                    alt="avatar"
                    className=" relative top-3 mr-4 h-10 w-10 rounded-full float-end "
                  />
                </span>
              </div>
            </Link>
          ))}
      </div>
      <div className="flex flex-col gap-4">
        <h2 className="mb-6 text-center font-display text-3xl font-medium text-jacarta-700 dark:text-white">
          Industrial
        </h2>
        {data
          .filter((item) => item.kategori === "Industrial")
          .map((item) => (
            <Link
              href={item.link}
              className="flex rounded-2.5xl border border-jacarta-100 bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent dark:bg-jacarta-700"
              key={item.id}
            >
              <figure className="mr-4 shrink-0">
                <Image
                  src={item.img}
                  alt="coin icon"
                  width={100}
                  height={100}
                  className="h-100 w-100 rounded-2lg"
                  loading="lazy"
                />
              </figure>
              <div>
                <span className="block font-display font-semibold text-jacarta-700 dark:text-white">
                  {item.name}
                </span>

                <span className="text-sm dark:text-jacarta-300">
                  <span className="text-green">{item.deskripsi}</span>
                </span>
                <span className="py-4">
                  <br />
                  <Image
                    width={40}
                    height={40}
                    src={item.authorImage}
                    alt="avatar"
                    className=" relative top-3 mr-4 h-10 w-10 rounded-full float-end "
                  />
                </span>
              </div>
            </Link>
          ))}
      </div>
      <div className="flex flex-col gap-4">
        <h2 className="mb-6 text-center font-display text-3xl font-medium text-jacarta-700 dark:text-white">
          Tour Guide
        </h2>
        {data
          .filter((item) => item.kategori === "Tour Guide")
          .map((item) => (
            <Link
              href={item.link}
              className="flex rounded-2.5xl border border-jacarta-100 bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent dark:bg-jacarta-700"
              key={item.id}
            >
              <figure className="mr-4 shrink-0">
                <Image
                  src={item.img}
                  alt="coin icon"
                  width={100}
                  height={100}
                  className="h-100 w-100 rounded-2lg"
                  loading="lazy"
                />
              </figure>
              <div>
                <span className="block font-display font-semibold text-jacarta-700 dark:text-white">
                  {item.name}
                </span>

                <span className="text-sm dark:text-jacarta-300">
                  <span className="text-green">{item.deskripsi}</span>
                </span>
                <span className="py-4">
                  <br />
                  <Image
                    width={40}
                    height={40}
                    src={item.authorImage}
                    alt="avatar"
                    className=" relative top-3 mr-4 h-10 w-10 rounded-full float-end "
                  />
                </span>
              </div>
            </Link>
          ))}
      </div>
    </>
  );
};

export default SingleCoin;
