import Link from "next/link";
import React, { useState } from "react";
import { hero_5_data } from "../../data/coverflow_data";
import Image from "next/image";
import {
  hero_3_swiper_data,
  hero_3_swiper_thumbs_data,
} from "../../data/hero_3_swiper_data";

import Card from "./3DCarousel/Card3D";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs, Autoplay } from "swiper";
// import Carousel from "./3DCarousel/Caraousel";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

const Hero_5 = () => {
  const [activeThumb, setActiveThumb] = useState(null);

  let cards = [
    {
      key: 1,
      content: <Card imagen="/images/home/hero.png" />,
    },
    {
      key: 2,
      content: (
        <Card imagen="https://updates.theme-fusion.com/wp-content/uploads/2017/12/acf_pro.png" />
      ),
    },
    {
      key: 3,
      content: (
        <Card imagen="https://updates.theme-fusion.com/wp-content/uploads/2017/12/layer_slider_plugin_thumb.png" />
      ),
    },
    {
      key: 4,
      content: (
        <Card imagen="https://updates.theme-fusion.com/wp-content/uploads/2016/08/slider_revolution-1.png" />
      ),
    },
    {
      key: 5,
      content: (
        <Card imagen="https://updates.theme-fusion.com/wp-content/uploads/2019/01/pwa_880_660.jpg" />
      ),
    },
  ];
  return (
    <>
      {/* <!-- Hero --> */}
      <section className="relative py-10 md:pt-32 ">
        <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <Image
            width={1519}
            height={773}
            priority
            src="./images/gradient.jpg"
            alt="gradient"
            className="h-full w-full object-cover"
          />
        </picture>
        <picture className="pointer-events-none absolute inset-0 -z-10 hidden dark:block">
          <Image
            width={1519}
            height={773}
            priority
            className="h-full w-full"
            src="./images/gradient_dark.jpg"
            alt="gradient dark"
          />
        </picture>

        <div className="h-full px-6 xl:px-20 lg:px-20">
          <div className="lg:grid xl:grid h-full items-center gap-4 grid-cols-12 lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-12 xs:grid-cols-12 xxs:grid-cols-12">
            <div className="col-span-6 flex h-full flex-col items-center justify-center py-10  md:items-start md:py-20 xl:col-span-5 xl:pl-[20%] xl:pr-[10%]">
              <h1 className="xs:pl-0 xxs:pl-0 sm:pl-0 mb-6 text-center font-display text-5xl text-jacarta-700 dark:text-white md:text-left lg:text-5xl xl:text-4xl">
                AUGMEE Bisa Menggunakan 3D Model sebagai AR Dimana Saja
              </h1>
              <p className="xs:pl-0 xxs:pl-0 sm:pl-0 mb-8 text-center text-lg dark:text-jacarta-200 md:text-left">
                AUGMEE merupakan web aplikasi yang dapat meningkatkan efisiensi,
                penjualan, dan transisi digital Anda dengan satu platform 3D.
                Anda dapat mengonversi, mengoptimalkan, berbagi, dan
                mempublikasikan aset 3D Anda sebagai showcase AR dari format apa
                pun ke perangkat apa pun
              </p>
              <div className="flex space-x-4 xs:pl-0 xxs:pl-0 sm:pl-0">
                <Link
                  href="https://admin.augmee.id/"
                  className="w-80 rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                >
                  Get Statrted
                </Link>
                {/* <Link
                  href="/collection/explore_collection"
                  className="w-36 rounded-full bg-white py-3 px-8 text-center font-semibold text-accent shadow-white-volume transition-all hover:bg-accent-dark hover:text-white hover:shadow-accent-volume"
                >
                  Explore
                </Link> */}
              </div>
              <div className="mt-10 w-full sm:flex sm:space-x-4">
                <div className="mb-4 flex-1 rounded-2lg bg-white p-4 text-center dark:bg-white/[.15]">
                  <span className="block font-display text-3xl text-[#8DD059]">
                    10,568
                  </span>
                  <span className="block font-display text-sm text-jacarta-500 dark:text-white">
                    Total 3D
                  </span>
                </div>
                <div className="mb-4 flex-1 rounded-2lg bg-white p-4 text-center dark:bg-white/[.15]">
                  <span className="block font-display text-3xl text-[#737EF2]">
                    1,200
                  </span>
                  <span className="block font-display text-sm text-jacarta-500 dark:text-white">
                    Views
                  </span>
                </div>
                <div className="mb-4 flex-1 rounded-2lg bg-white p-4 text-center dark:bg-white/[.15]">
                  <span className="block font-display text-3xl text-[#F35BC7]">
                    6,897
                  </span>
                  <span className="block font-display text-sm text-jacarta-500 dark:text-white">
                    Product
                  </span>
                </div>
              </div>
            </div>

            {/* <!-- Hero images --> */}
            {/* <div className="relative col-span-6 xl:col-span-6 xl:col-start-7">
              
              <div className="md:flex md:space-x-2 xl:space-x-12">
                {hero_5_data.map((item, index) => {
                  const { id, img, title, authorImage, authorName, subItem } =
                    item;
                  const itemLink = img
                    .split("/")
                    .slice(-1)
                    .toString()
                    .replace("_2lg.jpg", "")
                    .replace(".gif", "");
                  return (
                    <div
                      className={
                        index === 0
                          ? "mb-6 md:flex md:w-1/2 md:items-center"
                          : "space-y-6 md:w-1/2 xl:space-y-12"
                      }
                      key={id}
                    >
                      <article>
                        <div className="block overflow-hidden rounded-2.5xl bg-white shadow-md transition-shadow hover:shadow-lg dark:bg-jacarta-700">
                          <figure className="relative">
                            <Link href={``}>
                              <Image
                                width={406}
                                height={437}
                                src={img}
                                alt="item 1"
                                className="w-full h-full object-cover"
                              />
                            </Link>
                          </figure>
                          <div className="p-6">
                            <div className="flex">
                              <Link href="" className="shrink-0">
                               
                              </Link>
                              <div>
                                <Link
                                  href={``}
                                  className="block"
                                >
                                  <span className="font-display text-lg leading-none text-jacarta-700 hover:text-accent dark:text-white">
                                    {title}
                                  </span>
                                </Link>
                                <Link
                                  href="/user/avatar_6"
                                  className="text-2xs text-accent"
                                >
                                  {authorName}
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </article>

                      {subItem &&
                        subItem.map(
                          ({ id, img, title, authorImage, authorName }) => {
                            const itemLink = img
                              .split("/")
                              .slice(-1)
                              .toString()
                              .replace(".jpg", "")
                              .replace(".gif", "")
                              .replace("_lg", "");
                            return (
                              <div className="md:w-3/4" key={id}>
                                <article>
                                  <div className="block overflow-hidden rounded-2.5xl bg-white shadow-md transition-shadow hover:shadow-lg dark:bg-jacarta-700">
                                    <figure className="relative">
                                      <Link href={``}>
                                        <Image
                                          width={234}
                                          height={234}
                                          src={img}
                                          alt="item 1"
                                          className="w-full h-full object-cover"
                                        />
                                      </Link>
                                    </figure>
                                    <div className="p-6">
                                      <div className="flex">
                                        <Link
                                          href="/user/avatar_6"
                                          className="shrink-0"
                                        >
                                        
                                        </Link>
                                        <div>
                                          <Link
                                            href={``}
                                            className="block"
                                          >
                                            <span className="font-display text-lg leading-none text-jacarta-700 hover:text-accent dark:text-white">
                                              {title}
                                            </span>
                                          </Link>
                                          <Link
                                            href="/user/avatar_6"
                                            className="text-2xs text-accent"
                                          >
                                            {authorName}
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </article>
                              </div>
                            );
                          }
                        )}
                    </div>
                  );
                })}
              </div>
            </div> */}

            <div
              style={{ top: "80px" }}
              className="relative col-span-6 xxs:col-span-12 xs:col-span-12 sm:col-span-6 md:col-span-6 xl:col-span-6 xl:col-start-7"
            >
              {/* <div className="md:flex md:space-x-2 xl:space-x-12">
                <Image
                  width={560}
                  height={560}
                  src="./images/home/hero.png"
                  alt="hero"
                  className="hero-img mt-8 inline-block w-72 rotate-[8deg] sm:w-full lg:w-[24rem] xl:w-[35rem]"
                />
                <Image
                  width={740}
                  height={602}
                  src="./images/hero/3D_elements.png"
                  alt="floating image"
                  className="animate-fly absolute top-0 md:-right-[10%] "
                />
                <Carousel
                  cards={cards}
                  height="500px"
                  width="30%"
                  margin="0 auto"
                  offset={2}
                  showArrows={false}
                />
              </div> */}
              <div className="relative text-center xxs:pl-4 xxs:text-right xs:pl-4 xs:text-right sm:pl-4 sm:text-right  md:text-right lg:pl-32 lg:text-right">
                {/* <Image
                  width={724}
                  height={670}
                  src="./images/home/hero.png"
                  alt="crypto consultant hero"
                  className="inline-block mt-20 rotate-[8deg] rounded-2.5xl"
                /> */}
                <Swiper
                  modules={[FreeMode, Navigation, Thumbs, Autoplay]}
                  loop={true}
                  spaceBetween={10}
                  navigation={true}
                  thumbs={{
                    swiper:
                      activeThumb && !activeThumb.destroyed
                        ? activeThumb
                        : null,
                  }}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                  }}
                  className="full-slider h-screen"
                  style={{zIndex: 1}}
                >
                  {hero_3_swiper_data.map((item) => {
                    const { image, id, title, name, titleLink, userLink } =
                      item;
                    return (
                      <SwiperSlide key={id}>
                        {/* <div className="swiper-slide after:bg-jacarta-900/60 after:absolute after:inset-0">
                          <div className="container relative z-10 h-full pt-40">
                            <h2 className="font-display text-2xl font-semibold text-white">
                              <Link href={titleLink}>{title}</Link>
                            </h2>
                            <Link
                              href={userLink}
                              className="text-2xs font-medium text-white"
                            >
                              {name}
                            </Link>
                          </div> */}

                          <Image
                            width={724}
                            height={670}
                            // width={1519}
                            // height={722}
                            src={image}
                            // className="absolute inset-0 h-full w-full object-cover"
                            className="inline-block mt-20 rotate-[8deg] rounded-2.5xl"
                            // alt={title}
                          />
                        {/* </div> */}
                      </SwiperSlide>
                    );
                  })}
                </Swiper>

                <div className="absolute top-2 inline-block animate-fly rounded-2lg bg-[#EAF7E1] p-6 shadow-2xl sm:left-[5%] md:left-20"
                  style={{zIndex: 2}}
                  >
                  <div className="flex gap-4">
                    <span className="inline-flex h-12 w-12 items-center justify-center rounded-full bg-white">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        className="fill-green"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M4 5v14h16V5H4zM3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm11.793 6.793L13 8h5v5l-1.793-1.793-3.864 3.864-2.121-2.121-2.829 2.828-1.414-1.414 4.243-4.243 2.121 2.122 2.45-2.45z" />
                      </svg>
                    </span>
                    <div className="text-left">
                      <span className="block font-display text-3xl text-green">
                        Used
                      </span>
                      <span className="block font-display text-sm text-jacarta-600">
                        everywhere
                      </span>
                    </div>
                  </div>
                </div>

                {/* <Image
                  width={560}
                  height={560}
                  src="./images/home/hero.png"
                  alt="hero"
                  className="hero-img mt-8 inline-block w-72 rotate-[8deg] sm:w-full lg:w-[24rem] xl:w-[35rem]"
                /> */}

                <Image
                style={{zIndex: 2, top: "-100px"}}
                  width={740}
                  height={602}
                  src="./images/hero/3D_elements.png"
                  alt="floating image"
                  className="animate-fly absolute md:-right-[10%]"
                  
                />
              </div>
              <section>
                <div
                  style={{ top: "480px" }}
                  className="absolute inset-x-0 bottom-12"
                >
                  <div className="container">
                    <Swiper
                      modules={[FreeMode, Navigation, Thumbs]}
                      onSwiper={setActiveThumb}
                      loop={false}
                      spaceBetween={10}
                      slidesPerView="auto"
                      breakpoints={{
                        100: {
                          slidesPerView: 2,
                        },
                        768: {
                          slidesPerView: 3,
                        },
                      }}
                      className=" full-slider-thumbs swiper-initialized swiper-horizontal swiper-pointer-events swiper-thumbs"
                    >
                      {hero_3_swiper_thumbs_data.map((item) => {
                        const { image, id } = item;
                        return (
                          <SwiperSlide
                            className="swiper-slide cursor-pointer rounded p-5 swiper-slide-duplicate swiper-slide-duplicate-next "
                            key={id}
                          >
                            <Image
                              width={343}
                              height={188}
                              src={image}
                              className="w-full h-full object-cover rounded-lg"
                              alt="thumb 1"
                            />
                            <div className="carousel-progress relative -bottom-5 z-10 -ml-5 -mr-5 h-0.5 bg-white/20 ">
                              <div className="progress bg-accent absolute h-0.5 w-0"></div>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- end hero --> */}
    </>
  );
};

export default Hero_5;
