export const coverflow_data = [
  {
    img: "./images/home/IDCARD.png",
    title: "ID Cards",
    authorImage: "./images/home/bts1.png",
    authorName: "Produk dan Retail",
    id: "ID CARD",
    link: "https://ar.augmee.id/IDCard/index.html?id=1",
  },
  {
    img: "./images/home/Pompa.png",
    title: "Pompa",
    authorImage: "./images/home/bts1.png",
    authorName: "Produk dan Retail",
    id: "Pompa",
    link: "https://ar.augmee.id/",
  },
  {
    img: "./images/home/industrialWTP1.jpeg",
    title: "Water Treatment Plant",
    authorImage: "./images/home/bts1.png",
    authorName: "Industrial",
    id: "Industrial AR",
    link: "https://ar.augmee.id/markerless/wtp",

  },
  {
    img: "./images/home/simulasi.png",
    title: "Augmee Simulation",
    authorImage: "./images/home/bts1.png",
    authorName: "Produk dan Retail",
    id: "Augmee Simulation",
    link: "/detailTrending/simulasiCems",
  },
  {
    img: "./images/home/Trending4.png",
    title: "Game",
    authorImage: "./images/home/bts1.png",
    authorName: "Simulasi",
    id: "Game",
    link: "/detailTrending/industrial",

  },
  {
    img: "./images/home/industrial1.png",
    title: "Industrial AR",
    authorImage: "./images/home/bts1.png",
    authorName: "Industrial",
    id: "Industrial AR",
    link: "/detailTrending/industrial",

  },
  
  // {
  //   img: "./images/home/Trending6.png",
  //   title: "Rak Mini Market",
  //   authorImage: "./images/home/Lorem.png",
  //   authorName: "Industrial",
  //   id: "Rak Mini market",
  //   link: "https://ar.bernard.bdv.or.id/"

  // },
  // {
  //   img: "/images/products/item_16_lg.jpg",
  //   title: "Oceania \\\\ OVERSEER 017",
  //   authorImage: "/images/avatars/avatar_22_rounded.jpg",
  //   authorName: "MadeByM1KE",
  //   id: "6Oceania \\\\ OVERSEER 017",
  // },
  // {
  //   img: "/images/products/item_12_lg.jpg",
  //   title: "Light Bars",
  //   authorImage: "/images/avatars/avatar_17_rounded.jpg",
  //   authorName: "Wow Frens",
  //   id: "7Light Bars",
  // },
  // {
  //   img: "/images/products/item_14_lg.jpg",
  //   title: "Amazing NFT art",
  //   authorImage: "/images/avatars/avatar_19_rounded.jpg",
  //   authorName: "Lila Spacex",
  //   id: "8Amazing NFT art",
  // },
  // {
  //   img: "/images/products/item_15_lg.jpg",
  //   title: "SwagFox#133",
  //   authorImage: "/images/avatars/avatar_20_rounded.jpg",
  //   authorName: "Bored Bunny",
  //   id: "9SwagFox#133",
  // },
  // {
  //   img: "/images/products/item_13_lg.jpg",
  //   title: "Etherium NFT Launching Lab",
  //   authorImage: "/images/avatars/avatar_18_rounded.gif",
  //   authorName: "051_Hart",
  //   id: "10Etherium NFT Launching Lab",
  // },
  // {
  //   img: "/images/products/item_16_lg.jpg",
  //   title: "Oceania \\\\ OVERSEER 017",
  //   authorImage: "/images/avatars/avatar_22_rounded.jpg",
  //   authorName: "MadeByM1KE",
  //   id: "11Oceania \\\\ OVERSEER 017",
  // },
  // {
  //   img: "/images/products/item_12_lg.jpg",
  //   title: "Light Bars",
  //   authorImage: "/images/avatars/avatar_17_rounded.jpg",
  //   authorName: "Wow Frens",
  //   id: "12Light Bars",
  // },
  // {
  //   img: "/images/products/item_14_lg.jpg",
  //   title: "Amazing NFT art",
  //   authorImage: "/images/avatars/avatar_19_rounded.jpg",
  //   authorName: "Lila Spacex",
  //   id: "13Amazing NFT art",
  // },
  // {
  //   img: "/images/products/item_15_lg.jpg",
  //   title: "SwagFox#133",
  //   authorImage: "/images/avatars/avatar_20_rounded.jpg",
  //   authorName: "Bored Bunny",
  //   id: "14SwagFox#133",
  // },
];

export const hero_5_data = [
  {
    img: "./images/home/hero1.jpg",
    title: "Retail AR",
    authorImage: "./images/avatars/avatar_18_rounded.gif",
    authorName: "",
    id: "Retail AR",
  },
  {
    img: "./images/home/hero2.jpg",
    title: "Industrial AR",
    authorImage: "./images/avatars/avatar_22_rounded.jpg",
    authorName: "",
    id: "Industrial AR",

    subItem: [
      {
        img: "./images/home/showcase4.png",
        title: "Tour Guide AR",
        authorImage: "./images/avatars/avatar_17_rounded.jpg",
        authorName: "",
        id: "Tour Guide AR",
      },
    ],
  },
];
