import DataProduct from "./dataProduct";
import { useState } from "react";


const index = () => {
  const [searchValue, setSearchValue] = useState("");

  const singleCoinContent = [
    {
      id: 1,
      kategori: "product dan retail",
      img: "./images/home/IDCARD.png",
      name: "ID CARD",
      deskripsi: "Produk dan Retail",
      authorImage: "./images/home/bts1.png",
      link: "https://ar.augmee.id/IDCard/index.html?id=1",
    },
    {
      id: 2,
      kategori: "product dan retail",
      img: "./images/home/Pompa.png",
      name: "Pompa",
      deskripsi: "Produk dan Retail",
      authorImage: "./images/home/bts1.png",
      link: "https://ar.augmee.id/",
    },
    {
      id: 3,
      kategori: "Industrial",
      img: "./images/home/industrial1.png",
      name: "Industrial AR",
      deskripsi: "Industrial",
      authorImage: "./images/home/bts1.png",
      link: "/detailTrending/industrial",
    },
    {
      id: 4,
      kategori: "Industrial",
      img: "./images/home/simulasi.png",
      name: "Simulasi",
      deskripsi: "Industrial",
      authorImage: "./images/home/bts1.png",
      link: "/detailTrending/simulasiCems",
    },
    {
      id: 5,
      kategori: "Industrial",
      img: "./images/home/industrialWTP.jpeg",
      name: "Water Treatment Plant",
      deskripsi: "Industrial",
      authorImage: "./images/home/bts1.png",
      link: "https://ar.augmee.id/markerless/wtp",
    },
    {
      id: 6,
      kategori: "Tour Guide",
      img: "./images/home/Trending4.png",
      name: "Game VR",
      deskripsi: "Simulasi",
      authorImage: "./images/home/bts1.png",
      link: "/detailTrending/industrial",
    },
  ];
  
  
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  }
  
  const filteredOptions = singleCoinContent.filter((item) =>
    item.name.toLowerCase().startsWith(searchValue.toLowerCase())
  );
  
  return (
    <>
      {/* <!-- Invest --> */}
      <section className="relative bg-light-base py-24 dark:bg-jacarta-900">
        <div className="container">
          {/* search */}
          <div> 
              <input
                className="absolute top-8 w-96 h-12 px-4 border border-jacarta-100 dark:border-transparent rounded-2xl dark:bg-jacarta-700 bg-white dark:text-white text-jacarta-700 focus:outline-none focus:ring-2 focus:ring-jacarta-300 transition-shadow hover:shadow-lg"
                type="text"
                value={searchValue}
                onChange={handleSearch}
                placeholder="Search"
              />
            </div>

          <div className="grid grid-cols-1 gap-3 md:grid-cols-2 md:gap-[1.875rem] lg:grid-cols-3"> 
            <DataProduct 
            data={singleCoinContent} />
          </div>
        </div>
      </section>
      {/* <!-- end invest --> */}
    </>
  );
};

export default index;
