import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import Image from "next/image";
import "tippy.js/dist/tippy.css";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import ArtData from "../../data/art_data";
import Link from "next/link";
import HeadLine from "../headLine";
import { useEffect, useState } from "react";


const ArtsCarousel = () => {
  const [dataCategories, setDataCategories] = useState([]);

  const getDataCategories = async () => {
		try {
			// setIdSetting(idSetting)
			const response = await fetch(`${process.env.NEXT_PUBLIC_API}api/categories`);
			if (!response.ok) {
				alert('Data tidak ditemukan');
				throw new Error('Network response was not ok.');
			}
			const result = await response.json();
			console.log(result, 'data Category');

			// Check if the result is an array before setting state
			if (Array.isArray(result)) {
				//@ts-ignore
				setDataCategories(result);
			} else {
				throw new Error('Response is not an array');
			}
		} catch (error) {
			console.error('There was a problem with the fetch operation:', error);
		}
	};

	useEffect(() => {
		getDataCategories();
	}, []);
  return (
    <div style={{paddingTop: '6rem'}}>
      <HeadLine
        text="Showcase AR"
        classes=" font-display text-jacarta-700 mb-8 text-center text-3xl dark:text-white"
      />
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        slidesPerView="auto"
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          900: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1100: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
        navigation={{
          nextEl: ".swiper-button-next1",
          prevEl: ".swiper-button-prev1",
        }}
        className=" card-slider-4-columns !py-5"
      >
        {dataCategories.map((item,key) => {
          // const { id, name, title, bigImage, userImage } = item;
          // const itemLink = bigImage
          //   .split("/")
          //   .slice(-1)
          //   .toString()
          //   .replace(".jpg", "")
          //   .replace(".gif", "")
          //   .replace("_lg", "");

          return (
            <SwiperSlide key={item}>
              <article>
                <div className="dark:bg-jacarta-700 rounded-lg block overflow-hidden bg-white shadow-md transition-shadow hover:shadow-lg">
                  <figure className="relative">
                    <Link href={ArtData?.[key].link}>
                      <Image
                        width={379}
                        height={430}
                        src={ArtData?.[key].bigImage}
                        alt="item 1"
                        className="swiper-lazy h-[430px] w-full object-cover swiper-lazy-loaded rounded-2.5xl"
                      />
                    </Link>
                  </figure>
                  <div className="p-6">
                    <div className="flex">
                      {/* <Link
                        href={``}
                        className="shrink-0 mr-4"
                      >
                       
                        <Image
                          src={userImage}
                          alt={name}
                          height={40}
                          width={40}
                          className="mr-4 h-10 w-10 rounded-full object-cover"
                        />
                      </Link> */}
                      <div>
                        <Link href={``} className="block">
                          <span className="font-display hover:text-accent text-jacarta-700 text-lg leading-none dark:text-white">
                            {dataCategories?.[key].name}
                          </span>
                        </Link>
                        <Link
                          href={``}
                          className="text-accent text-2xs"
                        >
                          {dataCategories?.[key].kind}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </SwiperSlide>
          );
        })}
      </Swiper>

      {/* <!-- Slider Navigation --> */}
      <div className="group swiper-button-prev1 shadow-white-volume absolute !top-1/2 !-left-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl sm:!-left-6 after:hidden">
        <MdKeyboardArrowLeft />
      </div>
      <div className="group swiper-button-next1 shadow-white-volume absolute !top-1/2 !-right-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl sm:!-right-6 after:hidden">
        <MdKeyboardArrowRight />
      </div>
    </div>
  );
};

export default ArtsCarousel;
