import Image from "next/image";
import Link from "next/link";
import React from "react";

const Cta = () => {
  return (
    <div>
      {/* <!-- CTA --> */}
      <div className="relative  z-10 dark:bg-jacarta-900">
        <div className="container">
          <div className="relative overflow-hidden rounded-2.5xl px-16 pt-16 pb-8 shadow-md lg:px-24">
            <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
              <Image
                width={1519}
                height={773}
                priority
                src="./images/gradient_light.jpg"
                alt="gradient"
                className="h-full w-full object-cover"
              />
            </picture>
            <picture className="pointer-events-none absolute inset-0 -z-10 hidden dark:block">
              <Image
                width={1519}
                height={773}
                priority
                src="./images/gradient_dark.jpg"
                alt="gradient dark"
                className="h-full w-full object-cover"
              />
            </picture>
            <div className="items-center justify-between md:flex">
              <Image
                width={340}
                height={300}
                src="./images/home/aboutUs.png"
                alt="cta_illustration"
              />
              <div className="mb-6 md:w-1/2">
                <h2 className="mb-4 font-display text-2xl text-jacarta-700 dark:text-white sm:text-3xl">
                  Contact Us
                </h2>
                <div class="message">
                  <div class="container">
                    <div class="message-form">
                      

                      <p class="nama">Nama</p>
                      <input 
                      className="text-jacarta-700 placeholder-jacarta-500 focus:ring-accent border-jacarta-100 w-full rounded-2xl border py-3 px-4 pl-10 dark:border-transparent dark:bg-white/[.15] dark:text-white dark:placeholder-white"
                      type="text" placeholder="Masukan Nama" />

                      <p class="email">Email</p>
                      <input 
                      className="text-jacarta-700 placeholder-jacarta-500 focus:ring-accent border-jacarta-100 w-full rounded-2xl border py-3 px-4 pl-10 dark:border-transparent dark:bg-white/[.15] dark:text-white dark:placeholder-white"
                      type="text" placeholder="Masukan Email" />

                      <p class="pesan">Pesan</p>
                      <textarea 
                      className="text-jacarta-700 placeholder-jacarta-500 focus:ring-accent border-jacarta-100 w-full rounded-2xl border py-3 px-4 pl-10 dark:border-transparent dark:bg-white/[.15] dark:text-white dark:placeholder-white"
                      placeholder="Kirim Pesan"></textarea>


                    </div>
                  </div>
                </div>
                <Link
                  style={{position: "relative", left: "40%"}}
                  href=""
                  className="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                >
                  kirim
                </Link>
              </div>

            </div>
          </div>
        </div>
      </div>
      {/* <!-- end cta --> */}
    </div>
  );
};

export default Cta;
