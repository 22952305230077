import React, { useEffect, useRef, useState } from "react";
import Collection_category from "../../components/collectrions/collection_category";
import { Feature_collections, HeadLine } from "../../components/component";
import Meta from "../../components/Meta";
import Hero_5 from "../../components/hero/hero_5";
import Process from "../../components/blog/process";
import FilterCategoryItem from "../../components/categories/filterCategoryItem";
import CoverflowCarousel from "../../components/carousel/coverflowCarousel";

import Download from "../../components/blog/download";
import { Bids } from "../../components/component";
import ArtsCarousel from "../../components/carousel/artCarousel";
import Benifits from "../../components/dao/Benifits";
import Cta from "../../components/cta/cta";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import { useTheme } from "next-themes";
import Product from "../../components/cryto-trading/product";


const Home_5 = (props) => {
  const styles = `
        .styles-module_input__WFb9L {
            color: black;
        }
    `;

  const [messages, setMessages] = useState([]);
  const [messageInput, setMessageInput] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    if (messageInput.trim() !== "") {
      // Send message to real-time messaging service
      // Assuming setMessages adds the new message to the messages state
      setMessages((prevMessages) => [...prevMessages, messageInput]);
      setMessageInput("");
    }
  };

  const handleChange = (event) => {
    setMessageInput(event.target.value);
  };

  return (
    <>
      <Meta title="Home 5" />
      <div id="Home">
        <Hero_5 />
      </div>
      {/* <Feature_collections /> */}
      <div id="Trending">
        <CoverflowCarousel />
      </div>
      <Product />
      <Collection_category bgWhite={true} />
      {/* <div id="TopKreator">
        <Bids />
      </div> */}
      {/* <div id="Showcase">
        <div className="container">
          <div className="relative">
            <ArtsCarousel />
          </div>
        </div>
      </div> */}
      <div id="howItWork">
        <Benifits />
      </div>
      {/* <Process /> */}
      <Cta />
      <style>{styles}</style>
      <FloatingWhatsApp 
        className="dark:hidden"
        {...props}
        phoneNumber="+6289666608447"
        accountName="Firdaus Haqi"
        allowEsc
        allowClickAway
        notification
        notificationSound
        avatar="https://www.w3schools.com/w3images/avatar3.png"
        onSubmit={handleSubmit}
        darkMode={false}
        statusMessage={'Admin'}
        // chatMessage={messageInput}
        // messages={messages}
      />

      <FloatingWhatsApp
        className="hidden dark:block"
        {...props}
        phoneNumber="+6289666608447"
        accountName="Firdaus Haqi"
        allowEsc
        allowClickAway
        notification
        notificationSound
        avatar="https://www.w3schools.com/w3images/avatar3.png"
        onSubmit={handleSubmit}
        darkMode={true}
        statusMessage={'Admin'}
        // chatMessage={messageInput}
        // messages={messages}
      />

      {/* <Collection_category bgWhite={true} /> */}
      {/* <div>
       
        <section className="py-24">
          <div className="container">
            <HeadLine
              image="https://cdn.jsdelivr.net/npm/emoji-datasource-apple@7.0.2/img/apple/64/26a1.png"
              text="Trending categories"
              classes="mb-8 text-center font-display text-3xl text-jacarta-700 dark:text-white"
            />
            <FilterCategoryItem />

          </div>
        </section>
       
      </div> */}
      {/* <Download /> */}
    </>
  );
};

export default Home_5;
