const ArtData = [
	{
		id: 0,
		bigImage: './images/home/Pompa.png ',
		userImage: './images/avatars/avatar_17_rounded.jpg',
		name: 'Monitoring Pompa ',
		title: 'Industrial',
		category: 'industrial',
		link: 'https://ar.augmee.id/',
	},
	{
		id: 1,
		bigImage: './images/home/IDCARD.png',
		userImage: './images/avatars/avatar_18_rounded.gif',
		name: 'ID CARD',
		title: 'Produk dan retail',
		category: 'produk dan retail',
		link: 'https://ar.augmee.id/IDCard/index.html?id=1',
	},
	{
		id: 2,
		bigImage: './images/home/showcase3.png',
		userImage: './images/avatars/avatar_22_rounded.jpg',
		name: 'Jam Tangan',
		title: 'Produk dan Retail',
		category: 'produk dan retail',
		link: '',
	},
	{
		id: 3,
		bigImage: './images/home/TourGuide1.jpeg',
		userImage: './images/avatars/avatar_19_rounded.jpg',
		name: 'Tour Guide AR',
		title: 'Tour Guide',
		category: 'tour guide',
		link: '',

	},
	{
		id: 4,
		bigImage: './images/home/showcase5.png',
		userImage: './images/avatars/avatar_20_rounded.jpg',
		name: 'HSE Rute Evakuasi',
		title: 'Industrial',
		category: 'industrial',
		link: '',

	},
	{
		id: 5,
		bigImage: './images/home/showcase6.png',
		userImage: './images/avatars/avatar_20_rounded.jpg',
		name: 'Helm',
		title: 'Produk dan Retail',
		category: 'produk dan retail',
		link: ''
	},
	{
		id: 6,
		bigImage: './images/home/showcase7.png',
		userImage: './images/avatars/avatar_20_rounded.jpg',
		name: 'Manufactur',
		title: 'Industrial',
		category: 'industrial',
		link: '',

	},
	{
		id: 7,
		bigImage: './images/home/showcase8.png',
		userImage: './images/avatars/avatar_20_rounded.jpg',
		name: 'Sofa',
		title: 'Produk dan Retail',
		category: 'produk dan retail',
		link: '',

	}
];

export default ArtData;
